<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'property-ad': true,
    'small-size': size === 'small',
  }">
    <div class="blog-post-container">
      <div class="d-flex">
        <div class="thumb-frame col-md-4">
          <router-link :to="{name: 'BlogSinglePost', params: { id: id}}" class="blog-post-title" v-if="image">
          <div class="thumb-image" v-bind:style="{ 'background-image': 'url(/storage/' + image + ')' }"></div>
          </router-link>
        </div>
        <div class="blog-post-text col-md-8">
          <router-link :to="{name: 'BlogSinglePost', params: { id: id}}" class="blog-post-title">
            {{ title }}
          </router-link>
          <div class="subtitle">
          <span class="category me-2">
            {{ category?.title }}
          </span>
            <span class="separator me-2">/</span>
            <span class="date">
            {{ date }}
          </span>
          </div>
          <p class="description" v-if="false">
            {{ description }}
          </p>
          <router-link :to="{name: 'BlogSinglePost', params: { id: id}}"  class="button">
            <span class="me-2">Прочети</span>
            <svg width="26" height="8" viewBox="0 0 26 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.3536 4.35356C25.5488 4.15829 25.5488 3.84171 25.3536 3.64645L22.1716 0.464468C21.9763 0.269206 21.6597 0.269206 21.4645 0.464468C21.2692 0.65973 21.2692 0.976313 21.4645 1.17157L24.2929 4L21.4645 6.82843C21.2692 7.02369 21.2692 7.34027 21.4645 7.53554C21.6597 7.7308 21.9763 7.7308 22.1716 7.53554L25.3536 4.35356ZM-4.37114e-08 4.5L25 4.5L25 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="#FF7A00"/>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

