import PremiumLabelProperty from "@/components/Common/Labels/PremiumLabelProperty/PremiumLabelProperty";

export default {
    name: 'BlogPostItem',
    props: {
        id: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: null
        }
    },
    components: {
        PremiumLabelProperty

    },
    mounted() {
    }
}