<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="label-premium">
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_242_32984)">
        <path d="M7.45794 7.79904C5.57551 7.79904 3.69307 7.79904 1.81064 7.80463C1.6833 7.80463 1.639 7.7711 1.6224 7.64259C1.49505 6.75975 1.35664 5.87132 1.22376 4.98848C1.09088 4.17828 0.96908 3.37367 0.847276 2.56347C0.814056 2.33438 0.869422 2.17234 1.03552 2.06618C1.20162 1.96001 1.38986 1.97678 1.57257 2.11647C2.3643 2.71434 3.16156 3.3178 3.95883 3.92126C4.35192 4.2174 4.69519 4.16152 4.96648 3.75363C5.63641 2.74228 6.3008 1.73092 6.97072 0.71957C7.15343 0.440191 7.42472 0.356377 7.69601 0.473717C7.81228 0.529592 7.89533 0.618994 7.96177 0.725158C8.63723 1.74769 9.31269 2.77021 9.98815 3.79833C10.1321 4.02183 10.3314 4.128 10.5972 4.10006C10.7301 4.08888 10.8408 4.01624 10.9404 3.93802C11.7266 3.34015 12.5184 2.74786 13.3046 2.14999C13.3987 2.08294 13.4873 2.0103 13.6091 1.99913C13.9025 1.98236 14.1295 2.21145 14.0852 2.5076C13.9856 3.18369 13.8748 3.86538 13.7752 4.54148C13.6423 5.41873 13.5094 6.30157 13.371 7.17882C13.3433 7.35203 13.3101 7.52525 13.2935 7.69846C13.2824 7.7711 13.2547 7.79345 13.1883 7.79345C13.1495 7.79345 13.1163 7.79345 13.0776 7.79345C11.2062 7.79904 9.3293 7.79904 7.45794 7.79904Z" fill="#FCB745"/>
        <path d="M7.45811 8.77725C9.34608 8.77725 11.234 8.77725 13.122 8.77166C13.2604 8.77166 13.2881 8.81078 13.2881 8.93929C13.277 9.30248 13.2881 9.66568 13.2826 10.0233C13.2826 10.4759 13.039 10.7217 12.596 10.7217C9.17444 10.7217 5.75285 10.7217 2.33678 10.7217C1.88832 10.7217 1.64471 10.4759 1.64471 10.0233C1.64471 9.6545 1.65025 9.28013 1.63918 8.91135C1.63918 8.79401 1.67239 8.76608 1.78313 8.76608C3.67663 8.77725 5.57014 8.77725 7.45811 8.77725Z" fill="#FCB645"/>
      </g>
      <defs>
        <clipPath id="clip0_242_32984">
          <rect width="13.2545" height="10.3091" fill="white" transform="translate(0.836365 0.41814)"/>
        </clipPath>
      </defs>
    </svg>
    <div>Premium</div>
  </div>
</template>

