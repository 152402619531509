<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'white-background':  theme === 'solid-white',
  }">

    <div class="about-button d-flex justify-content-between">
      <div class="me-4">{{ text }}</div>
      <div class="ms-4">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_246_30873)">
            <path
                d="M6.25 13C6.25 13.4142 6.58579 13.75 7 13.75C7.41421 13.75 7.75 13.4142 7.75 13L6.25 13ZM7.75 1C7.75 0.585786 7.41421 0.25 7 0.25C6.58579 0.25 6.25 0.585786 6.25 1L7.75 1ZM7.75 13L7.75 1L6.25 1L6.25 13L7.75 13Z"
                fill="#232C34"/>
            <path
                d="M12.4697 7.53021C12.7626 7.8231 13.2374 7.8231 13.5303 7.53021C13.8232 7.23731 13.8232 6.76244 13.5303 6.46955L12.4697 7.53021ZM7 0.999878L7.53033 0.469548C7.23744 0.176654 6.76256 0.176654 6.46967 0.469548L7 0.999878ZM0.46967 6.46955C0.176777 6.76244 0.176777 7.23731 0.46967 7.53021C0.762562 7.8231 1.23744 7.8231 1.53033 7.53021L0.46967 6.46955ZM13.5303 6.46955L7.53033 0.469548L6.46967 1.53021L12.4697 7.53021L13.5303 6.46955ZM6.46967 0.469548L0.46967 6.46955L1.53033 7.53021L7.53033 1.53021L6.46967 0.469548Z"
                fill="#232C34"/>
          </g>
          <defs>
            <clipPath id="clip0_246_30873">
              <rect width="14" height="14" fill="white" transform="translate(14 14) rotate(-180)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>

